<template>
  <header-line></header-line>
  <div class="content" v-if="!loading" v-bind:class="{'header_wo_logo': data.hideHeaderLogo}">
    <div class="site-width">
      <div class="vouchers__tabs">
        <ul class="tabs__menu">
          <li class="tabs__item">
            <router-link class="tabs__link" v-bind:to="{name: 'home', params: {lang}}">
              {{ t('label.home') }}
            </router-link>
          </li>
          <li class="tabs__item">
            <router-link class="tabs__link" v-bind:to="{name: 'shop', params: {lang}}">
              {{ t('label.shop') }}
            </router-link>
          </li>
          <li class="tabs__item">
            <router-link class="tabs__link ui-tabs-active" v-bind:to="{name: 'about', params: {lang}}">
              {{ t('label.about') }}
            </router-link>
          </li>
        </ul>
      </div>
      <article class="page page--article">
        <div class="article__content richtext" v-html="terms"></div>
      </article>
    </div>
  </div>
  <page-loader v-else></page-loader>
  <footer-line></footer-line>
</template>

<script>
import {useI18n} from 'vue-i18n'
import HeaderLine from "@/components/HeaderLine";
import FooterLine from "@/components/FooterLine";
import {useStore} from 'vuex'
import {computed, onMounted, ref, watchEffect} from 'vue'
import {ACTION_TYPES} from "@/constants";
import PageLoader from "@/components/PageLoader";

export default {
  name: 'terms',
  components: {PageLoader, FooterLine, HeaderLine},
  setup() {
    const store = useStore();
    const terms = computed(() => store.getters.terms);
    const data = computed(() => store.getters.data);

    onMounted(() => {
      if (!terms.value && !store.getters.isTermsLoading) {
        store.dispatch(ACTION_TYPES.loadTerms);
      }
    })

    const loading = ref(false);
    watchEffect(() => {
      loading.value = !terms.value;
    })

    const i18n = useI18n();

    return {t: i18n.t, loading, terms, data};
  }
}
</script>
